<template>
    <div class="review-card">
        <div class="review-card__score">
            <slot name="score" />
        </div>
        <div class="review-card__author">
            <slot name="author" />
        </div>
        <div class="review-card__quote">
            <slot />
        </div>
    </div>
</template>

<style lang="less">
.review-card {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2xs);
    padding-bottom: var(--spacing-2xs);

    @media @q-md-min {
        gap: var(--spacing-sm);
        padding-bottom: var(--spacing-sm);
    }
}

.review-card__author {
    .typography-label();
    opacity: .6;
}

.review-card__quote {
    line-height: var(--lineHeight-md);
}
</style>
