<template>
    <div class="reviews-list">
        <div
            v-if="property || averageRating"
            class="reviews-list__property-rating"
        >
            <strong v-if="averageRating">{{ averageRating }} {{ $t('reviews.overall-score') }}</strong>
            <template v-if="averageRating && property">&nbsp;</template>
            <template v-if="property">
                <NuxtLink
                    :to="localePath(`/${property.uri}`)"
                    @click="$emit('property-clicked')"
                >
                    {{ property.title }}
                </NuxtLink>
            </template>
        </div>

        <template
            v-for="review, index in limitedReviews"
            :key="`review-${index}`"
        >
            <ReviewCard>
                <template #score>
                    <BaseReviewStars :rating="review.rating" />
                </template>
                <template #author>
                    {{ review.author }} / {{ format(review.date, 'dd-MM-yyyy') }}
                </template>
                <template #default>
                    {{ review.quote }}
                </template>
            </ReviewCard>
        </template>
    </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns';

defineEmits(['property-clicked']);

const props = defineProps({
    property: {
        type: Object,
        required: false,
        default: null
    },
    averageRating: {
        type: Number,
        required: false,
        default: 0
    },
    reviews: {
        type: Array as PropType<Array<{ author: string; date: string; rating: number, quote: string }>>,
        required: true,
        default: () => []
    },
});

const localePath = useLocalePath();

const limitedReviews = props.reviews?.slice(0, 10);
</script>

<style lang="less">
.reviews-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);

    @media @q-md-min {
        gap: var(--spacing-xl);
    }

    .review-card:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, .2);
    }
}
</style>
