<template>
    <BaseSection
        class="review-quote base-section--full-bleed"
        background-color="lime"
    >
        <div class="review-quote__author-score">
            <span v-if="$slots.author" class="review-quote__author">
                <slot name="author" />
            </span>

            <template v-if="$slots.author && $slots.date"> / </template>

            <span v-if="$slots.date" class="review-quote__date">
                <slot name="date" />
            </span>

            <span v-if="$slots.score" class="review-quote__score">
                <slot name="score" />
            </span>
        </div>

        <blockquote class="review-quote__quote">
            <slot />
        </blockquote>

        <div class="review-quote__reviews">
            <span v-if="$slots.totalScore || averageRating" class="review-quote__total-score">
                <strong>
                    <slot name="totalScore">
                        {{ averageRating }}
                    </slot>
                    {{ $t('reviews.overall-score') }}
                </strong>
            </span>

            <template v-if="($slots.totalScore || averageRating) && $slots.property">&nbsp;</template>

            <slot name="property" /><br />

            <BaseArrowLink
                v-if="reviews?.length"
                class="review-quote__all-reviews"
                @click="modalStore.open('reviews-modal')"
            >
                {{ $t('reviews.see-all-reviews') }}
            </BaseArrowLink>
        </div>

        <Teleport to="#reviews-modal">
            <ReviewsList
                v-if="reviews?.length"
                :property="property"
                :average-rating="averageRating"
                :reviews="reviews"
                @property-clicked="modalStore.close('reviews-modal')"
            />
        </Teleport>
    </BaseSection>
</template>

<script setup>
import useReviews from '~/composables/useReviews';
import { useModalStore } from '~/store/modal';

const props = defineProps({
    property: {
        type: Object,
        required: false,
    },
});

const { getAverageRating, getLatestReviews, getAllLatestReviews } = useReviews();
const modalStore = useModalStore();

const averageRating = ref(null);
const reviews = ref([]);

if (props.property) {
    const result = await getAverageRating(props.property.slug);
    averageRating.value = result?.averageRating;

    const latestReviews = await getLatestReviews(props.property.slug);
    reviews.value = latestReviews;
} else if (!props.property) {
    const allLatestReviews = await getAllLatestReviews();
    reviews.value = allLatestReviews;
}
</script>

<style lang="less">
.base-section.review-quote {
    padding-top: 0;

    .base-section__inner {
        padding: var(--spacing-xl) var(--spacing-md);

        @media @q-lg-min {
            padding: 5rem  0;
        }
    }

    .review-quote__author-score {
        .typography-label();
    }

    .review-quote__score {
        @media @q-md-min {
            display: inline-block;
            margin-left: 2rem;
            vertical-align: middle;
        }
    }

    .base-section__body {
        max-width: 52.0625rem;

        @media @q-lg-min {
            width: 52.0625rem;
        }
    }

    .review-quote__quote {
        .typography-quote();
        margin-left: 0;
        margin-right: 0;
        margin-top: 1.06rem;
    }

    .review-quote__reviews {
        text-align: right;
    }
    
    .review-quote__all-reviews {
        .typography-label();
        margin-top: .25rem;
        cursor: pointer;
    }
}
</style>