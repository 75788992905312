<template>
    <ReviewQuoteBlock
        v-if="props.review || pending"
        :property="props.review.property?.[0]"
    >
        <template
            v-if="props.review.author"
            #author
        >
            {{ props.review.author }}
        </template>

        <template
            v-if="props.review.date"
            #date
        >
            {{ format(props.review.date, 'dd-MM-yyyy') }}
        </template>

        <template 
            v-if="props.review.quote" 
            #default
        >
            {{ props.review.quote }}
        </template>

        <template
            v-if="props.review.score"
            #score
        >
            <BaseReviewStars :rating="props.review.score" />
        </template>

        <template
            v-if="props.review.property?.[0]"
            #property
        >
            {{ props.review.property?.[0].title }}
        </template>
    </ReviewQuoteBlock>
</template>

<script setup>
import { format } from 'date-fns';

const props = defineProps({
    review: {
        type: Object,
        required: true,
        default: () => {}
    }
});
</script>
